import { ref } from 'vue'
import { usePodStore } from '@/stores/pod'
import { useOptions } from '@/composables/useOptions'

import type { ModelObject } from '@/types/products'
import type { ConfiguratorRenderOptions } from '@/types/configurator'

type ConfiguratorRenderOptionKeys = keyof ConfiguratorRenderOptions

export async function useOptionValidator(
  changedOption: ConfiguratorRenderOptionKeys
): Promise<void> {
  const pod = usePodStore()

  const model = ref<ModelObject | null>(null)

  //   Fill data from store
  useOptions().then((optionStore) => {
    model.value = optionStore.getModel(pod.options)
    if (model.value === null) return
  })

  if (
    changedOption === 'podtype' ||
    changedOption === 'width' ||
    changedOption === 'depth' ||
    changedOption === 'berging'
  ) {
    useOptions().then((optionStore) => {
      // Get the default number of windows
      const defaultWindows: number =
        Number(optionStore.getModel(pod.options)?.standaard_aantal_ramen) ?? 0

      // Set defaultWindows times a 0 in the array
      if (leftWindowsAvailable()) {
        pod.options.windows.left = Array.from({ length: defaultWindows }, () => 0)
        pod.options.windows.right = []
      } else {
        pod.options.windows.right = Array.from({ length: defaultWindows }, () => 0)
        pod.options.windows.left = []
      }

      // Check if the selected door is still available
      if (pod.options.door_type !== 'enkeldraai') {
        const availableDoors = optionStore.getAvailableDoors(pod.options)

        const doorLookup = () => {
          switch (pod.options.door_type) {
            case 'dubbeldraai':
              return 'Dubbele draaideuren'
            case 'enkelschuif':
              return 'Enkele schuifpui'
            case 'dubbelschuif':
              return 'Dubbele schuifpui'
            default:
              return 'dubbeldraai'
          }
        }

        if (!availableDoors?.find((door) => door.optie === doorLookup())) {
          if (
            pod.options.door_type === 'dubbeldraai' &&
            availableDoors?.find((door) => door.optie === 'Enkele openslaande draaideur')
          ) {
            pod.options.door_type = 'enkeldraai'
          } else {
            pod.options.door_type = 'enkeldraai'
          }
        }
      }

      if (pod.options.width < 5 && pod.options.berging === true) {
        pod.options.berging = false
      }

      if (changedOption !== 'berging') {
        // Restore voorportaal
        pod.options.planken = pod.getPlanken()
      }

      if (changedOption === 'podtype') {
        setAvailableSize(pod, optionStore)
      }
    })
  }

  if (changedOption === 'orientation') {
    if (pod.options.orientation !== 'plat_dak' && pod.options.storage_mirrored) {
      pod.options.storage_mirrored = false
    }
  }
}

function leftWindowsAvailable(): boolean {
  const pod = usePodStore()

  if (pod.options.berging === false || pod.options.podtype === 'alpha') {
    return true
  }

  // Check the orientation of the pod
  if (pod.options.orientation === 'links_hoog') {
    return true
  }

  if (pod.options.orientation === 'plat_dak' && pod.options.storage_mirrored) {
    return true
  }
  return false
}

function setAvailableSize(pod: any, optionStore: any) {
  interface Option {
    label: string
    value: any
  }

  const { width, depth } = optionStore.getAvailableSizes()

  const sizeAvailable = ref(false)

  width.value.map((w: Option) => {
    if (w.value === pod.options.width) {
      sizeAvailable.value = true
    }
  })

  if (!sizeAvailable.value) {
    // Set closest width
    const closestWidth = width.value.reduce((prev: Option, curr: Option) =>
      Math.abs(curr.value - pod.options.width) < Math.abs(prev.value - pod.options.width)
        ? curr
        : prev
    )

    pod.options.width = closestWidth.value
  }

  sizeAvailable.value = false

  depth.value.map((d: Option) => {
    if (d.value === pod.options.depth) {
      sizeAvailable.value = true
    }
  })

  if (!sizeAvailable.value) {
    // Set closest depth
    const closestDepth = depth.value.reduce((prev: Option, curr: Option) =>
      Math.abs(curr.value - pod.options.depth) < Math.abs(prev.value - pod.options.depth)
        ? curr
        : prev
    )

    pod.options.depth = closestDepth.value
  }
}
